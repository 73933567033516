import React, { useState, Fragment, useRef } from 'react';
import {Form, Button, ButtonToolbar, Col, Container, Modal} from 'react-bootstrap/';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import './WarrantyFields.css';

const WarrantyFields = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [formSubmitted, setSubmitted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [sendingImage, setSendingImage] = useState(false);
    const [sentImage, setSentImage] = useState(false);
    const [sentImageFailed, setSentImageFailed] = useState(false);
    const [receiptFile, setReceiptFile] = useState(null);
    const [receiptImage, setReceiptImage] = useState(null);
    const [showImageNotif, setShowImageNotif] = useState(false);

    const handleClose = () => setShowImageNotif(false);
    const handleShow = () => setShowImageNotif(true);

    const baseUrl = '/form'
    const formRef = useRef(null);

    var receiptUpload = '';
    var useUpload = '';
    var reviewImage = '';

    const submitImage = async(forms) => {
        const response = await axios.post(baseUrl + '/submitimage', forms, {
            onUploadProgress: (progressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(progress);
            },
            onDownloadProgress: (progressEvent) => {
              const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              // console.log(progress);
              setProgress(progress);
            },
          });
        
        return { response, progress };
    }

    const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onloadend = () => {
            // console.log('Base64 Result:', reader.result); // Log the base64 result
            resolve(reader.result); // Resolve the promise with the base64 string
        };
        
        reader.onerror = (error) => {
            // console.error('Error reading file:', error);
            reject(error); // Reject the promise on error
        };
        
        reader.readAsDataURL(file); // Read the file as a Data URL (base64)
        });
    };


    const handleUseUpload = event => {
        
        if(event.target.files[0].type.match('application/pdf')) {
            getBase64(event.target.files[0], (result) => {
                useUpload = result;
            });
        }
        else if(event.target.files[0].type.match('image/*')) {
            getBase64(event.target.files[0], (result) => {
                useUpload = result;
            });
        }
        else  {
            alert("Only images or PDF files can be uploaded.")
            event.target.value = null;
            return
        }
    }

    const handleExternalSubmit = () => {
        formRef.current.requestSubmit(); 
        setShowImageNotif(false);
    };

    const handleSubmitImage = async (event) => {

    const form = event.currentTarget;
    event.preventDefault();

    setSendingImage(true);
    setSentImage(false);
    setSubmitted(false);
    setSentImageFailed(false);

    try {
        const file = event.target.files[0];

        if (file.type.match('application/pdf') || file.type.match('image/*')) {
            const receiptUpload = await getBase64(file); // Await the base64 result

            const forms = {
                "receipt_image": receiptUpload
            };

            const response = await axios.post(baseUrl + '/submitimage', forms, {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                },
                onDownloadProgress: (progressEvent) => {
                    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                },
            });

            if (response.status === 413) {
                setSentImageFailed("The images passed in are too big. Please make sure each image is under 1mb.");
                alert("The images passed in are too big. Please make sure each image is under 1mb.");
            } else if (response.status === 400) {
                setSentImageFailed("Failed to upload.");
                alert("Failed to upload.");
            } else if (response.status === 200) {
                setSentImage(true);
                setReceiptImage(response.data.receipt_image);
            }
        } else {
            setSentImageFailed("Only images or PDF files can be uploaded.");
            alert("Only images or PDF files can be uploaded.");
            event.target.value = null;
            setSendingImage(false);
            return;
        }
    } catch (exception) {
        console.error("An error occurred:", exception);
        if (exception.response.status === 413) {
            setSentImageFailed("The images passed in are too big. Please make sure each image is under 1mb.");
            alert("The images passed in are too big. Please make sure each image is under 1mb.");
        } else if (exception.response.status === 400) {
            setSentImageFailed("Failed to upload.");
            alert("Failed to upload.");
        }else{
            setSentImageFailed("An error occurred submitting your receipt image.");
            alert("An error occurred submitting your receipt image.");
        }
    }

    setSendingImage(false);
    }

    const handleSubmit = async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      
      if(!sentImage || sendingImage ){
        setShowImageNotif(true)
      }
      else if (form.checkValidity() === false) {
        event.stopPropagation();
        alert("Submitted fields are not valid.");
      } else if (sentImage == false){
        alert("Please wait until upload is complete");
      } else {
        try {
            const forms = {
                "brand": props.appBrand,
                "first_name": form.elements.firstName.value,
                "middle_initial": form.elements.middleInitial.value,
                "last_name": form.elements.lastName.value,
                "street_address_1": form.elements.addr1.value,
                "street_address_2": form.elements.addr2.value,
                "city": form.elements.city.value,
                "state": form.elements.state.value,
                "country": form.elements.country.value,
                "zipcode": form.elements.zip.value,
                "telephone_number": form.elements.phoneNum.value,
                "fax_number": form.elements.faxNum.value,
                "email_address": form.elements.emailAddress.value,
                "date_of_purchase": form.elements.datePurchased.value,
                "purchase_location": form.elements.purchasedFrom.value,
                "product_model": form.elements.productModel.value,
                // "receipt_image": receiptUpload,
                "gender": form.elements.gender.value,
                "occupation": form.elements.occupation.value,
                "products_owned": form.elements.previouslyOwned.value,
                "age_group": form.elements.ageGroup.value,
                "family_income": form.elements.householdIncome.value,
                "review_image": useUpload,
                "purchase_reason": form.elements.purchaseReason.value,
                "use_reason": form.elements.useReason.value,
                "rating": form.elements.rating.value,
                "liked_features": form.elements.likedFeatures.value,
                "receipt_image": receiptImage
            }
            setLoading(true);
            const response = await axios.post(baseUrl + '/submitfield', forms);

            if(response.status === 413) {
                alert("The images passed in are too big. Please make sure each image is under 1mb.")
            }
            else if(response.status === 400) {
                alert("Failed to upload.");
            }
            else if(response.status === 200) {
                setSubmitted(true);
            }
            setLoading(false);
        }
        catch(exception) {
            setLoading(false);
            alert("An error occurred submitting your information.");
        }
      }
    };

    return(
        <>
        <Modal show={showImageNotif} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Status of Upload Receipt Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               { sendingImage ?
                    (<span> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait until upload is complete 
                        <LinearProgress variant="determinate" value={progress} />
                        </span> ) :
                                    !sentImage ? ( sentImageFailed ? sentImageFailed : "Please submit receipt image" ): "Image Uploaded. Close this modal then submit data. Or Click Submit"
                }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {sentImage &&
              <Button variant="primary" onClick={handleExternalSubmit}>
                Sumbit
              </Button> }
            </Modal.Footer>
        </Modal>

        <div className="field-container">
            { !formSubmitted && 
            <Container fluid="sm">
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <h3 className="header">Warranty Information</h3>
                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control required placeholder="First Name" maxLength="15"/>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="middleInitial">
                                <Form.Label>M.I.</Form.Label>
                                <Form.Control placeholder="M.I." maxLength="1"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control required placeholder="Last Name" maxLength="15"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group className="bottom-margin" controlId="addr1">
                        <Form.Label>Street Address 1*</Form.Label>
                        <Form.Control required placeholder="Street Address" maxLength="50"/>
                    </Form.Group>

                    <Form.Group className="bottom-margin" controlId="addr2">
                        <Form.Label>Street Address 2</Form.Label>
                        <Form.Control placeholder="Apt #, Suite #, etc." maxLength="50"/>
                    </Form.Group>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="city">
                                <Form.Label>City*</Form.Label>
                                <Form.Control required placeholder="City" maxLength="50"/>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="state">
                                <Form.Label>State*</Form.Label>
                                <Form.Control required placeholder="ex. CA" maxLength="20"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="zip">
                                <Form.Label>Zip Code*</Form.Label>
                                <Form.Control required placeholder="Zip Code" maxLength="15"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="country">
                                <Form.Label>Country*</Form.Label>
                                <Form.Control required placeholder="Country" maxLength="50"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="phoneNum">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control placeholder="ex. 555-555-5555" maxLength="15"/>
                            </Form.Group>
                        </Col> 
                        <Col>
                            <Form.Group controlId="faxNum">
                                <Form.Label>Fax Number</Form.Label>
                                <Form.Control placeholder="ex. 555-555-5555" maxLength="15"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group className="bottom-margin" controlId="emailAddress">
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control required type="email" placeholder="ex. name@example.com" maxLength="50"/>
                    </Form.Group>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="datePurchased">
                                <Form.Label>Date of Purchase*</Form.Label>
                                <Form.Control required type="date" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="purchasedFrom">    
                                <Form.Label>Purchased From*</Form.Label>
                                <Form.Control required placeholder="ie. Amazon" maxLength="50"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="productModel">
                                <Form.Label>Product Model #*</Form.Label>
                                <Form.Control required placeholder="Model #" maxLength="50"/>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.File>
                                <Form.File.Label>Upload receipt image</Form.File.Label>
                                <Form.File.Input required accept="image/*, application/pdf" onChange={handleSubmitImage}/>
                                <Form.Control.Feedback type="invalid">Invalid file type. Please upload an image or PDF with max size 5 MB.</Form.Control.Feedback>
                                <Form.File.Label>
                                    { sendingImage &&
                                    (<span> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Uploading </span> ) }
                                    { sentImage &&  ("Uploading Complete") }
                                </Form.File.Label>
                            </Form.File>
                            <br/>
                            <LinearProgress variant="determinate" value={progress} />
                        </Col>
                    </Form.Row>

                    <h3 className="header">Customer Review (Optional)</h3>
                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="gender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control as="select">
                                    <option value="" selected> -- Select Gender -- </option>
                                    <option>M</option>
                                    <option>F</option>
                                    <option value="O">Other</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="occupation">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control placeholder="Occupation" maxLength="20"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group className="bottom-margin" controlId="previouslyOwned">
                        <Form.Label>Did you own any of our products before the current purchase?</Form.Label>
                        <Form.Control as="select">
                            <option value="" selected> -- Y/N -- </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="ageGroup">
                                <Form.Label>Age Group</Form.Label>
                                <Form.Control as="select">
                                    <option value="" selected> -- Select Age Group -- </option>
                                    <option>18-24</option>
                                    <option>25-39</option>
                                    <option>40-49</option>
                                    <option>50-59</option>
                                    <option>60+</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="householdIncome">
                                <Form.Label>Household Income</Form.Label>
                                <Form.Control as="select">
                                    <option value="" selected> -- Select Income -- </option>
                                    <option>0 - 19,999</option>
                                    <option>20,000 - 39,999</option>
                                    <option>40,000 - 59,999</option>
                                    <option>60,000 - 79,999</option>
                                    <option>80,000 - 99,999</option>
                                    <option>100,000+</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group className="bottom-margin">
                        <Form.File>
                            <Form.File.Label>Please show us how you use our product:</Form.File.Label>
                            <Form.File.Input accept="image/*, application/pdf" onChange={handleUseUpload}/>
                        </Form.File>
                    </Form.Group>

                    <Form.Group className="bottom-margin">
                        <Form.Group controlId="purchaseReason">
                            <Form.Label>What is the reason for your purchase?</Form.Label>
                            <Form.Control as="textarea" placeholder="Reason for purchase (150 characters)" maxLength="150"/>
                        </Form.Group>
                    </Form.Group>

                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="useReason">
                                <Form.Label>Is this purchase for business or personal use?</Form.Label>
                                <Form.Control as="select">
                                    <option value="" selected> -- Business or Personal Use -- </option>
                                    <option>Business use</option>
                                    <option>Personal use</option>
                                    <option>Both business and personal use</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group controlId="rating">
                                <Form.Label>Rate the product (1-5)</Form.Label>
                                <Form.Control placeholder="1-5" maxLength="1" />
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group  className="bottom-margin"controlId="likedFeatures">
                        <Form.Label>What features do you like about the product?</Form.Label>
                        <Form.Control as="textarea" placeholder="Features (100 characters)" maxLength="100" />
                    </Form.Group>

                    <ButtonToolbar className="submit-button text-center d-block">
                        <Button variant="primary" type="submit" disabled={isLoading }>
                            { isLoading ? 
                                <Fragment>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Loading...
                                </Fragment> : "Submit" }
                        </Button>
                    </ButtonToolbar>
                    
                </Form> 
            </Container>}

            { formSubmitted && 
            <Container fluid="sm">
                <h3>Thank you for submitting your information!</h3>
            </Container> }
        </div>
        </>
    )
}

export default WarrantyFields