import React, {useState, Fragment} from 'react';
import {Form, Col, Button, Container, ButtonToolbar} from 'react-bootstrap';

import SearchTable from './SearchTable';
import searchService from '../Services/search.service';
import './DataSearch.css'

const DataSearch = () => {
    const [searchedFields, setSearchedFields] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSearch = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if(form.elements.purchaseDateStart.value && form.elements.purchaseDateEnd.value &&
           Date.parse(form.elements.purchaseDateStart.value) > Date.parse(form.elements.purchaseDateEnd.value)) {
            alert("The start date cannot be later than the end date.")
            return
        }
        if(form.elements.purchaseDateStart.value && Date.parse(form.elements.purchaseDateStart.value) > Date.now()) {
            alert("The start date cannot be later than today's date.")
            return
        }
        if(form.elements.purchaseDateEnd.value && Date.parse(form.elements.purchaseDateEnd.value) > Date.now()) {
            alert("The end date cannot be later than today's date.")
            return
        }
        try {
            const forms = {
                "first_name": form.elements.firstName.value,
                "last_name": form.elements.lastName.value,
                "date_of_purchase_start": form.elements.purchaseDateStart.value,
                "date_of_purchase_end": form.elements.purchaseDateEnd.value,
                "brand": form.elements.brand.value
            }

            setIsLoading(true);
            const response = await searchService.search(forms)
            setIsLoading(false) //After 1 second, set render to true
            if(response.status === 200) {
                setSearchedFields(response.data)
            }

        }
        catch(exception) {
            if(exception.response.status === 401) {
                alert("Error - login token is invalid or expired. Please log out and back in and try again.");
            }
            else
                alert("An error occurred while searching. Please check your input and try again.")
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Container fluid="sm">
                <h4>Search</h4>
                <Form onSubmit={handleSearch}>
                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control placeholder="First Name" maxLength="30"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control placeholder="Last Name" maxLength="30"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="brand">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control as="select">
                                    <option value="" selected> -- Select Brand -- </option>
                                    <option value="blueblower">Blueblower</option>
                                    <option value="masterforce">Masterforce</option>
                                    <option value="catfan">Caterpillar</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="bottom-margin">
                        <Col>
                            <Form.Group controlId="purchaseDateStart">
                                <Form.Label>Purchase Date Range Start</Form.Label>
                                <Form.Control type="date"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="purchaseDateEnd">
                                <Form.Label>Purchase Date Range End</Form.Label>
                                <Form.Control type="date"/>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <ButtonToolbar className="submit-button text-center d-block">
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            { isLoading ? 
                                <Fragment>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Loading...
                                </Fragment>: 
                                "Submit" }
                        </Button>
                    </ButtonToolbar>
                </Form>

                {searchedFields ? 
                    <SearchTable searchData={searchedFields}></SearchTable>
                    : null
                }
            </Container>
        </div>
    )
}

export default DataSearch