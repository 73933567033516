import React, {useState, useEffect} from 'react';
import { Nav, Navbar, Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

import WarrantyFields from './Components/WarrantyFields';
import LoginForm from './Components/LoginForm';
import UserContext from './Context/UserContext';
import PrivateRoute from './Components/PrivateRoute';
import DataSearch from './Components/DataSearch';
import loginService from './Services/login.service';
import './App.css';


function App() {
  const [user, setUser] = useState(null);
  const [brand, setBrand] = useState(null);
  useEffect(() => {
    getAppBrand()
  }, [])

  const getAppBrand = async () => {
    var response = null;
    try {
        response = await loginService.init()
    }
    catch(exception) {
        console.log(exception)
    }
    if(response && response.status === 200 && response.data !== '' && response.data !== null) {
        setBrand(response.data)
    }
  }

  return (
    <div>
      <Router>
        <UserContext.Provider value={{user, setUser}}>
          <Navbar bg="primary" variant="dark" sticky="top">
            <Nav className="mr-auto links-bar">
              <Link className="nav-link" to="/">Home</Link>
              <Link hidden={user === null} className="nav-link" to="/data_search">Search</Link>
            </Nav>
            <LoginForm className="mr-sm-2"></LoginForm>
          </Navbar>
        
          {brand ? <Image className="logo" src={require('./Images/' + brand + '-logo.png' )} alt="Logo"></Image> : null}
          <Switch>
            <Route path="/" exact render={() => <WarrantyFields appBrand={brand}/>} />
            <PrivateRoute authed={user} path="/data_search" component={DataSearch} /> 
          </Switch>
          {brand && brand==="catfan"? <Image className="logo" src={require('./Images/' + brand + '-footer.png' )} alt="Logo"></Image> : null}
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
