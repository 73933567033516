import axios from 'axios';
const baseUrl = '/search'

const search = async (fields) => {
    const response = await axios.post(baseUrl, fields)
    return response
}

export default { search }

