import React, {useState} from 'react';
import {CSVLink} from 'react-csv';
import {Modal, Button, Table, Image,} from 'react-bootstrap'

import searchService from '../Services/search.service';
import './SearchTable.css'

const SearchTable = (props) => {
    const [modalState, setModalState] = useState({data: null, show: false})
    const [isLoading, setLoading] = useState(false);

    const tableHeader = ["First Name", "Last Name", "Brand", "User Info"]
    const csvHeaders = ["First Name", "Last Name", "Email Address", "State", "Country", "Brand"]
    const tableMap = {"First Name": "first_name", "Last Name": "last_name", "Brand": "brand"}
    const csvMap = {...tableMap, "Email Address": "email_address", "State": "state", "Country": "country"}

    async function handleShowModal(data) {
        try {
            const forms = {
                "first_name": "",
                "last_name": "",
                "date_of_purchase_start": "",
                "date_of_purchase_end": "",
                "brand": "",
                "warranty_id": data.warranty_id
            }

            setLoading(true);
            const response = await(searchService.search(forms));

            if(response.status === 200) {
                setModalState((prevState) => ({
                    data: response.data[0],
                    show: true
                }))
            }
            else
                alert("An error occurred getting user data.")
            setLoading(false);
        }
        catch(exception) {
            alert("An error occurred getting user data.")
            setLoading(false);
        }
    }

    const handleModalClose = () => {
        setModalState((prevState) => ({
            data: null,
            show: false
        }))
    }

    return (
        <div>
            <CSVLink data={props.searchData.map(data => {
                var newData = {}
                for(const header in csvHeaders) {
                    newData[csvHeaders[header]] = data[csvMap[csvHeaders[header]]]
                }
                return newData
            })} headers={csvHeaders}>Export Data to CSV
            </CSVLink>

            <Table>
                <thead>
                    <tr>
                        <th></th>
                        {tableHeader.map((header, index) => (
                            <th key={"Header" + index.toString()}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.searchData.map((data, index) => (
                        <tr key={"Row" + index.toString()}>
                            <td>{index}</td>
                            {tableHeader.map(header => {
                                if(header !== "User Info") 
                                    return <td key={header + index.toString()}>{data[tableMap[header]]}</td>;
                                else
                                    return (<td key={header + index.toString()}>
                                        <Button onClick={() => handleShowModal(data)} disabled={isLoading}>Details</Button>
                                    </td>);
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
            
            { modalState.show ? 
            <Modal show={modalState.show} onHide={handleModalClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{modalState.data["first_name"]} {modalState.data["middle_initial"]} {modalState.data["last_name"]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(modalState.data).map(key => (
                        <div key={key}>
                            <h6 style={{"display": "inline-block", "padding-right": "10px", "font-weight": "bold"}}>{key + ":"}</h6>
                            {(key === "review_image" || key === "receipt_image") ? <Image src={modalState.data[key]} fluid />
                                                                                 : <span style={{display: "inline-block"}}>{" " + modalState.data[key]}</span>
                            }
                            
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose}>Close</Button>
                </Modal.Footer>
            </Modal> : null }
        </div>
    )
}

export default SearchTable