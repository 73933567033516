import React, {useState, useContext, useEffect} from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';

import loginService from '../Services/login.service';
import './LoginForm.css';
import UserContext from '../Context/UserContext';

const LoginForm = () => {
    const [loginClicked, setLoginClicked] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const {user, setUser} = useContext(UserContext)

    useEffect(() => {
        loginService.refresh().then(response => {
            if(response && response.status === 200 && response.data !== '' && response.data !== null) {
                setUser(true)
                setLoginClicked(true)
            }
        })
    }, [setUser])
    
    const redirectLogin = () => {
        setLoginClicked(true)
    }
    
    const handleLogin = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        try {
            setIsLoggingIn(true)
            await loginService.login(form.elements.username.value, form.elements.password.value)
            setUser(true)
        }
        catch(exception) {
            alert("Unable to login successfully.")
        }
        setIsLoggingIn(false)
    }

    const logout = async () => {
        setLoginClicked(false)
        setUser(null)
        await loginService.logout()
    }

    return (
        <div>
            {user ? // logged in
                <Row className="logged-in">
                    <Button variant="secondary" onClick={logout}>Logout</Button>
                </Row>
                
            : loginClicked ? 
            <Form onSubmit={handleLogin}>
                <Form.Row>
                    <Col>
                        <Form.Group controlId="username">
                            <Form.Control required className="login-field" size="sm" placeholder="Username"/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="password">
                            <Form.Control required className="login-field" size="sm" type="password" placeholder="*****"/>
                        </Form.Group>
                    </Col>
                    <Col xs={3} className="justify-content-md-end">
                        <Button variant="secondary" type="submit" disabled={isLoggingIn}>Submit</Button>
                    </Col>
                </Form.Row>
            </Form>:
            <Button variant="secondary" onClick={redirectLogin}>Login</Button> }
        </div>
    )
}

export default LoginForm