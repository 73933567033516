import axios from 'axios';
const baseUrl = '/login'

const login = async (username, password) => {
    const response = await axios.post(baseUrl, {username: username, password: password})
    return response.data
}

const logout = async () => {
    const response = await axios.get(baseUrl + '/logout')
    return response
}

const refresh = async () => {
    const response = await axios.get(baseUrl + '/refresh')
    return response
}

const init = async () => {
    const response = await axios.get(baseUrl + '/init')
    return response
}

export default { login, logout, refresh, init }