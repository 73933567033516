import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, authed, ...rest}) => {
    return (
        <Route
            render= {(props) => authed !== null && authed !== undefined
            ? <Component /> 
            : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
        />
    )
}

export default PrivateRoute